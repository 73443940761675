/** @jsx jsx */
import { jsx } from 'theme-ui';
import ProtectedRoute from 'src/components/composite/ProtectedRoute';
import { ChartContextWrapper } from 'src/components/composite/chart-generator/wrappers/ChartContextWrapper';
import { MultiComparison } from '@charts';

export default function () {
  return (
    <ChartContextWrapper>
      <ProtectedRoute path="/specific-chart" component={MultiComparison} />
    </ChartContextWrapper>
  );
}
